.company-form {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background: linear-gradient(to top, #136df594, #f8466dcc),
		url('../../../../assets/images/home/ceo.jpg') no-repeat no-repeat center;
	background-size: cover;
	&-content {
		width: 100%;
		padding: 100px;
		display: flex;
		justify-content: center;
		&-inputs {
			position: relative;
			animation: fromTop 0.3s linear;
			.lets-start {
				color: #006ebde5;
				text-align: center;
				font-weight: 600;
			}
			width: 500px;
			background: white;
			padding: 40px;
			border-radius: 20px;
			.form-label {
				color: black;
			}
			.btn-primary {
				background-color: #006ebde5;
				width: 100%;
			}
		}
	}
}
