.contact-page-container {
	margin-bottom: 100px;
	.background-image {
		position: relative;
		.contact-page-content {
			position: absolute;
			top: 40%;
			h2 {
				color: white;
				text-align: center;
				font-size: 60px;
			}
		}
	}
	.contact-us-form {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		background: linear-gradient(to top, #136df594, #c3f74bcc),
			url('../../../../assets/images/contact/contact.jpg') no-repeat no-repeat
				center center;
		background-size: cover;
		padding: 100px;

		.contact-us-content-inputs {
			position: relative;
			animation: fromTop 0.3s linear;
			.lets-start {
				color: #006ebde5;
				text-align: center;
				font-weight: 600;
			}
			width: 400px;
			background: white;
			padding: 40px;
			border-radius: 20px;
			.form-label {
				color: black;
			}
			.btn-primary {
				background-color: #006ebde5;
				width: 100%;
			}
		}
	}
	.contact-page-contact {
		margin: 90px !important;
		img {
			margin: 0 40px 10px 0;
		}
		h3 {
			color: #006ebd;
		}
		p {
			width: 180px;
		}
	}

	.register-container {
		background: linear-gradient(rgba(0, 110, 189, 0.9), rgba(0, 110, 189, 0.9)),
			url('../../../../assets/images/Dashboard/bg/bg_register.png');
		height: 100%;
		color: White;
		height: 250px;
		.register-btn {
			margin-left: 30px;
		}
	}
}
