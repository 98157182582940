.companies-container {
	margin-bottom: 120px;
	a {
		text-decoration: none;
	}
	h1 {
		font-size: 54px;
	}
	.card-deck {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 100px;
		.card {
			width: 18rem;
			margin: 10px;
			border: 2px solid black;
			border-radius: 20px;
			padding: 10px;
			position: relative;
			top: 0;
			transition: top ease-in 0.3s;
			background-color: #dff1ff;
			&:hover {
				top: -30px;
			}

			& a {
				color: '#006EBD';
			}
			&-title {
				font-weight: 600;
				text-align: center;
				margin-bottom: 30px;
			}
			&-details {
				font-size: 16px;
				font-weight: 600;
				color: black;
			}
		}
	}
}
