@-webkit-keyframes TopBottom {
  0% {
    bottom: 300px;
  }
  100% {
    bottom: 136px;
  }
}
@keyframes TopBottom {
  0% {
    bottom: 300px;
  }
  100% {
    bottom: 136px;
  }
}
.homepage-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.homepage-container-background {
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(61, 178, 245, 0.8)), url("../../../../assets/images/home/home-background.jpg") no-repeat no-repeat center -100px;
  background-size: cover;
}
.homepage-container-header {
  color: white;
}
.homepage-container-header h1 {
  font-size: 4rem;
  font-weight: 600;
}
.homepage-container-content {
  display: flex;
  flex: 1 1 0px;
  color: White;
  padding: 30px;
  background: linear-gradient(to top, rgba(105, 157, 235, 0.5803921569), rgba(224, 253, 92, 0.8));
}
.homepage-container-content .student {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(61, 245, 190, 0.8)), url("../../../../assets/images/home/student.jpg") no-repeat no-repeat center;
  background-size: cover;
  padding: 20px;
  margin-right: 20px;
}
.homepage-container-content .student h3 {
  margin-bottom: 20px;
}
.homepage-container-content .student .student-info {
  max-width: 600px;
}
.homepage-container-content .company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 20px;
  text-align: end;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(248, 70, 109, 0.8)), url("../../../../assets/images/home/ceo.jpg") no-repeat no-repeat center;
  background-size: cover;
  padding: 20px;
}
.homepage-container-content .click-btn {
  width: 100px;
  text-align: center;
  border-radius: 10px;
}
.homepage-container .no-internships-available {
  width: 100%;
  height: 300px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 136px;
  -webkit-animation: TopBottom 0.5s linear;
          animation: TopBottom 0.5s linear;
}
.homepage-container .no-internships-available-content {
  height: 200px;
  width: 500px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 37px;
  border-radius: 20px;
  background-color: White;
  color: rgba(0, 110, 189, 0.8980392157);
  font-weight: 700;
}/*# sourceMappingURL=style.css.map */