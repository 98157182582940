.menu-container {
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 10;
	height: 196px;

	.scroll-header-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
		transition: all 0.4s ease-in-out;

		.images-container {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: 90px;
				transition: all 0.7s ease-in-out;
			}
		}
		.images-container-shrink {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: 40px;
				transition: all 0.7s ease-in-out;
			}
		}
		.text-container {
			span {
				color: #808080c7;
			}
		}
	}

	.navbar-container {
		width: 100%;
		font-family: noto-sans-bold;
		font-weight: 500;
		padding: 0.5rem 4rem;
	}

	.navbar-nav a {
		font-size: 17px;
	}
	.navbar-brand img {
		height: 80px;
	}

	.dropdown-menu {
		top: 40px;
	}

	.multi-level-dropdown .nav-link {
		color: black !important;
	}
}
