.students-list {
	position: relative;
	.students-background-image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: url('../../../../assets/images/Dashboard/bg/students.jpg')
			no-repeat center center fixed;
		background-size: cover;
		filter: blur(3px);
	}
	.students-container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		overflow-x: hidden;
		overflow-y: hidden;

		.students-content-container {
			position: relative;
			margin-top: 20px;
			.students-logo {
				display: flex;
				justify-content: center;
				img {
					background-color: white;
					position: relative;
					top: 60px;
					border-radius: 60px;
				}
			}
			.students-content {
				background: white;
				padding: 40px;
				margin: 20px;
				border-radius: 20px;
				height: 480px;
			}

			.student-email-btn {
				position: relative;
				margin-top: 20px;
				font-weight: bold;
				border: 2px solid #0d6efd;
				border-radius: 20px;
				text-align: center;
				&:hover .student-email {
					visibility: visible;
				}
				&:hover:before {
					content: 'Salveaza adresa';
				}
				&:hover span:first-child {
					display: none;
				}
				&:active span {
					display: none;
				}

				.student-email {
					visibility: hidden;
					background-color: black;
					color: #fff;
					text-align: center;
					border-radius: 6px;
					padding: 10px 3px;
					position: absolute;
					top: 30px;
					z-index: 1;
					left: 0px;
					text-align: center;
					&:hover {
						cursor: text;
					}
				}

				&:hover {
					cursor: pointer;
					background: yellow;
				}
			}
			.delete-student-btn {
				margin-top: 10px;
				font-weight: bold;
				border: 2px solid #0d6efd;
				border-radius: 20px;
				color: #dc3545;
				border-color: #dc3545;
				text-align: center;
				&:hover {
					cursor: pointer;
					background-color: #dc3545;
					color: white;
				}
			}
		}
	}
	.load-more-students {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		position: relative;
		z-index: 2;
		button {
			border-radius: 20px;
		}
	}
}
