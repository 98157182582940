.user-files-container .user-files-header {
  height: 480px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.user-files-container .user-files-header h1 {
  color: White;
  font-weight: bold;
  font-size: 50px;
  line-height: 63px;
  margin-left: 73px;
}
.user-files-container img {
  width: 100%;
  height: 480px;
  position: relative;
  z-index: 0;
}
.user-files-container h5:hover {
  cursor: pointer;
  text-decoration: underline;
}/*# sourceMappingURL=style.css.map */