.contact-page-container {
  margin-bottom: 100px;
}
.contact-page-container .background-image {
  position: relative;
}
.contact-page-container .background-image .contact-page-content {
  position: absolute;
  top: 40%;
}
.contact-page-container .background-image .contact-page-content h2 {
  color: white;
  text-align: center;
  font-size: 60px;
}
.contact-page-container .contact-us-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(195, 247, 75, 0.8)), url("../../../../assets/images/contact/contact.jpg") no-repeat no-repeat center center;
  background-size: cover;
  padding: 100px;
}
.contact-page-container .contact-us-form .contact-us-content-inputs {
  position: relative;
  -webkit-animation: fromTop 0.3s linear;
          animation: fromTop 0.3s linear;
  width: 400px;
  background: white;
  padding: 40px;
  border-radius: 20px;
}
.contact-page-container .contact-us-form .contact-us-content-inputs .lets-start {
  color: rgba(0, 110, 189, 0.8980392157);
  text-align: center;
  font-weight: 600;
}
.contact-page-container .contact-us-form .contact-us-content-inputs .form-label {
  color: black;
}
.contact-page-container .contact-us-form .contact-us-content-inputs .btn-primary {
  background-color: rgba(0, 110, 189, 0.8980392157);
  width: 100%;
}
.contact-page-container .contact-page-contact {
  margin: 90px !important;
}
.contact-page-container .contact-page-contact img {
  margin: 0 40px 10px 0;
}
.contact-page-container .contact-page-contact h3 {
  color: #006ebd;
}
.contact-page-container .contact-page-contact p {
  width: 180px;
}
.contact-page-container .register-container {
  background: linear-gradient(rgba(0, 110, 189, 0.9), rgba(0, 110, 189, 0.9)), url("../../../../assets/images/Dashboard/bg/bg_register.png");
  height: 100%;
  color: White;
  height: 250px;
}
.contact-page-container .register-container .register-btn {
  margin-left: 30px;
}/*# sourceMappingURL=style.css.map */