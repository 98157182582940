.profile-container {
	width: 100%;
	height: 100vh;
	position: relative;
	.profile-background-img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		filter: blur(3px);
		background: url('../../../../assets/images/Dashboard/bg/profil.jpg')
			no-repeat center center;
		background-size: cover;
	}
	label {
		color: #6f9fd7;
		margin-top: 10px;
		font-weight: bold;
	}
	.profile-content-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 100px;
		.profile-content {
			width: 500px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: white;
			border-radius: 20px;
			position: relative;
			.user-avatar {
				position: relative;
				bottom: 40px;
				svg {
					width: 78px;
					height: 78px;
					border-radius: 40px;
					box-shadow: 2px 4px 12px rgb(111 159 215 / 25%);
					background-color: white;
				}
			}
			.profile-info {
				margin: -30px 0 30px 0;
				padding: 20px;
			}
			.change-password {
				margin-top: -40px;
				margin-bottom: 40px;
				padding: 20px;
				display: flex;
				#lock-open {
					display: none;
				}
				h6 {
					margin-bottom: 0px;
					font-family: noto-sans;
					font-weight: 600;
					font-size: 18px;
					line-height: 25px;
					color: #006ebd;
				}
				&:hover {
					cursor: pointer;
					#lock-open {
						display: block;
					}
					#lock {
						display: none;
					}
				}
			}
			.accordion-item {
				max-width: 392px;
			}
			.change-password-input {
				padding: 20px;
				margin: -20px 0 50px 0px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.change-pass {
					position: relative;
				}
				.show-hide-pass {
					position: absolute;
					right: 116px;
					top: 127px;
				}
				input {
					max-width: 218px;
				}
				.btn-close {
					position: absolute;
					top: 20px;
					right: 22px;
				}
				#change-pass-btn {
					margin-top: 20px;
					color: white;
				}
				.invalid-pass-feedback,
				.success-pass-feedback {
					margin-top: 10px;
				}
				.invalid-pass-feedback {
					color: red;
					font-weight: bold;
				}
				.success-pass-feedback {
					color: green;
					font-weight: bold;
				}
			}
		}
	}

	.section-title {
		padding: 80px;
		filter: blur(0);
		h1 {
			color: #006ebd;
			text-shadow: 2px 2px black;
			font-size: 48px;
			font-weight: 700;
		}
	}
}
