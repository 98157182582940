.generic-page-container {
	overflow: hidden;
	font-size: 19px;
	.generic-page-background-image {
		position: relative;
		height: 428px;
		img {
			height: 100%;
			background-size: cover;
			object-fit: cover;
		}
	}
	.generic-page-header {
		position: absolute;
		top: 40%;
		h2 {
			color: white;
			text-align: center;
			font-size: 60px;
			text-shadow: 3px 3px #006ebd;
		}
	}
	.generic-page-context-container {
		background-color: #f5f5f5;
		padding: 100px;
		.generic-page-context-content {
			border: 2px solid white;
			background-color: white;
			padding: 30px;
			border-radius: 20px;
			box-shadow: 5px 10px 10px 10px #bcd6eb;
		}
	}
}
