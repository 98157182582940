@font-face {
	font-family: noto-sans-bold;
	src: url('./assets/fonts/NotoSans-Bold.ttf');
}

@font-face {
	font-family: noto-sans;
	src: url('./assets/fonts/NotoSans-Regular.ttf');
}

@font-face {
	font-family: montserrat-regular;
	src: url('./assets/fonts/Montserrat-Regular.ttf');
}

body {
	margin: 0;
	background-color: red;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: noto-sans !important;
	height: 100vh;
	overflow-x: hidden;
}
