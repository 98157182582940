.icontent-student {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-height: 892px) {
  .icontent-student {
    height: 100%;
  }
}
.icontent-student .iheader-student {
  color: white;
}
.icontent-student .internship-status .internship-info2 {
  font-weight: bold;
  font-size: 16px;
  color: green;
}
.icontent-student .internship-status .internship-info3 {
  font-weight: bold;
  font-size: 16px;
  color: grey;
}
.icontent-student .internship-status img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.icontent-student .internship-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../../../assets/images/Dashboard/bg/student-internships.jpg") no-repeat center center fixed;
  background-size: cover;
  filter: blur(3px);
}
.icontent-student .ilist-student {
  margin-top: 0;
  position: relative;
}
.icontent-student .ilist-student .icontainer-student {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5607843137);
  border-radius: 20px;
}
.icontent-student .ilist-student .icontainer-student .internship-content {
  background: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
}
.icontent-student .ilist-student .icontainer-student .internship-content .internship-description {
  display: flex;
  padding: 20px;
  font-size: 18px;
}
.icontent-student .ilist-student .icontainer-student .internship-content .internship-description .company-name {
  color: #006ebd;
  font-weight: bold;
  font-size: 17px;
}
.icontent-student .ilist-student .icontainer-student .internship-content .internship-description img {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 0;
}
.icontent-student .ilist-student .icontainer-student .internship-content .internship-description a {
  margin-left: 20px;
  height: 40px;
}

.internship-fallback {
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.internship-fallback p {
  max-width: 252px;
}/*# sourceMappingURL=style.css.map */