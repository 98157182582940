.internship-id-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../../assets/images/Dashboard/DashboardImages/internshipId.jpg") no-repeat center center;
  flex-direction: column;
}
.internship-id-container .accordion-body {
  height: 300px;
  overflow-y: scroll;
}
.internship-id-container .internship-id-content {
  position: fixed;
  height: 600px;
  width: 570px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  padding: 100px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #006ebd;
  margin-bottom: 120px;
}
.internship-id-container .internship-id-content p {
  color: #006ebd;
  font-weight: 800;
}
.internship-id-container .internship-id-content #student-name {
  color: black;
  margin: 0 3px 0 0;
}
.internship-id-container .internship-id-content .edit-internship-id {
  position: relative;
}
.internship-id-container .internship-id-content .edit-internship-id .btn-close {
  position: absolute;
  top: -81px !important;
  left: 428px !important;
}
.internship-id-container .internship-id-content .internship-info1 {
  font-weight: bold;
  font-size: 17px;
  color: grey;
}
.internship-id-container .internship-id-content .internship-info2 {
  font-weight: bold;
  font-size: 16px;
  color: green;
}
.internship-id-container .internship-id-content .internship-info3 {
  font-weight: bold;
  font-size: 16px;
  color: #e0a800;
}
.internship-id-container .internship-id-content img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.internship-id-container .internship-id-details {
  display: flex;
  margin: 20px 0 20px 0;
}
.internship-id-container .internship-id-details h6 {
  color: #006ebd;
  font-weight: 800;
  padding: 5px;
}
.internship-id-container .internship-id-details img {
  width: 30px;
  height: 30px;
}/*# sourceMappingURL=style.css.map */