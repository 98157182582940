.press-releases-container {
	::-webkit-scrollbar {
		width: 6px;
		margin-left: 2px;
	}

	::-webkit-scrollbar-track {
		background: gainsboro;
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb {
		background: #006ebd;
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	width: 100%;
	padding: 100px;
	background: url('../../../../assets/images/Announcement/anunturi2.jpg')
		no-repeat center center;
	background-size: cover;
	.press-releases-content {
		font-family: 'Raleway', sans-serif;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		h1 {
			color: #006ebd;
			text-shadow: 2px 2px white;
			font-size: 62px;
			font-weight: 600;
			line-height: 50px;
			margin: 0 0 24px;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
	}

	.list-group {
		height: 500px;
		border-radius: 10px;
		overflow: auto;

		&-item {
			padding: 15px;
			border: 3px solid #dff1ff;
			padding: 20px;
			border-left: none;
			border-right: none;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 15px;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
