.company-container {
  margin-bottom: 100px;
}
.company-container .background-image {
  position: relative;
}
.company-container .background-image .company-content {
  position: absolute;
  top: 40%;
}
.company-container .background-image .company-content h2 {
  color: white;
  text-align: center;
  font-size: 60px;
}
.company-container .company-contact {
  margin: 90px !important;
}
.company-container .company-contact img {
  margin: 0 40px 10px 0;
}
.company-container .company-contact h3 {
  color: #006ebd;
}
.company-container .company-contact p {
  width: 180px;
}
.company-container .company-description {
  font-size: 20px;
}
.company-container .register-container {
  background: linear-gradient(rgba(0, 110, 189, 0.9), rgba(0, 110, 189, 0.9)), url("../../../../assets/images/Dashboard/bg/bg_register.png");
  height: 100%;
  color: White;
  height: 250px;
}
.company-container .register-container .register-btn {
  margin-left: 30px;
}/*# sourceMappingURL=style.css.map */