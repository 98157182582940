.dashboard-content-image {
  height: 480px;
  width: 100%;
  position: relative;
  z-index: 0;
}

.welcome-user-container {
  height: 480px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.welcome-user-container h1 {
  color: White;
  font-weight: bold;
  font-size: 50px;
  line-height: 63px;
  margin-left: 73px;
}/*# sourceMappingURL=style.css.map */