@-webkit-keyframes fromTop {
  from {
    bottom: 100px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes fromTop {
  from {
    bottom: 100px;
  }
  to {
    bottom: 0px;
  }
}
.student-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(61, 245, 190, 0.8)), url("../../../../assets/images/home/student.jpg") no-repeat no-repeat center;
  background-size: cover;
}
.student-form-content {
  width: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
}
.student-form-content-inputs {
  position: relative;
  -webkit-animation: fromTop 0.3s linear;
          animation: fromTop 0.3s linear;
  width: 400px;
  background: white;
  padding: 40px;
  border-radius: 20px;
}
.student-form-content-inputs .lets-start {
  color: rgba(0, 110, 189, 0.8980392157);
  text-align: center;
  font-weight: 600;
}
.student-form-content-inputs .form-label {
  color: black;
}
.student-form-content-inputs .btn-primary {
  background-color: rgba(0, 110, 189, 0.8980392157);
  width: 100%;
}/*# sourceMappingURL=style.css.map */