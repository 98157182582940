.profile-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.profile-container .profile-background-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  filter: blur(3px);
  background: url("../../../../assets/images/Dashboard/bg/profil.jpg") no-repeat center center;
  background-size: cover;
}
.profile-container label {
  color: #6f9fd7;
  margin-top: 10px;
  font-weight: bold;
}
.profile-container .profile-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.profile-container .profile-content-container .profile-content {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  position: relative;
}
.profile-container .profile-content-container .profile-content .user-avatar {
  position: relative;
  bottom: 40px;
}
.profile-container .profile-content-container .profile-content .user-avatar svg {
  width: 78px;
  height: 78px;
  border-radius: 40px;
  box-shadow: 2px 4px 12px rgba(111, 159, 215, 0.25);
  background-color: white;
}
.profile-container .profile-content-container .profile-content .profile-info {
  margin: -30px 0 30px 0;
  padding: 20px;
}
.profile-container .profile-content-container .profile-content .change-password {
  margin-top: -40px;
  margin-bottom: 40px;
  padding: 20px;
  display: flex;
}
.profile-container .profile-content-container .profile-content .change-password #lock-open {
  display: none;
}
.profile-container .profile-content-container .profile-content .change-password h6 {
  margin-bottom: 0px;
  font-family: noto-sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #006ebd;
}
.profile-container .profile-content-container .profile-content .change-password:hover {
  cursor: pointer;
}
.profile-container .profile-content-container .profile-content .change-password:hover #lock-open {
  display: block;
}
.profile-container .profile-content-container .profile-content .change-password:hover #lock {
  display: none;
}
.profile-container .profile-content-container .profile-content .accordion-item {
  max-width: 392px;
}
.profile-container .profile-content-container .profile-content .change-password-input {
  padding: 20px;
  margin: -20px 0 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-container .profile-content-container .profile-content .change-password-input .change-pass {
  position: relative;
}
.profile-container .profile-content-container .profile-content .change-password-input .show-hide-pass {
  position: absolute;
  right: 116px;
  top: 127px;
}
.profile-container .profile-content-container .profile-content .change-password-input input {
  max-width: 218px;
}
.profile-container .profile-content-container .profile-content .change-password-input .btn-close {
  position: absolute;
  top: 20px;
  right: 22px;
}
.profile-container .profile-content-container .profile-content .change-password-input #change-pass-btn {
  margin-top: 20px;
  color: white;
}
.profile-container .profile-content-container .profile-content .change-password-input .invalid-pass-feedback,
.profile-container .profile-content-container .profile-content .change-password-input .success-pass-feedback {
  margin-top: 10px;
}
.profile-container .profile-content-container .profile-content .change-password-input .invalid-pass-feedback {
  color: red;
  font-weight: bold;
}
.profile-container .profile-content-container .profile-content .change-password-input .success-pass-feedback {
  color: green;
  font-weight: bold;
}
.profile-container .section-title {
  padding: 80px;
  filter: blur(0);
}
.profile-container .section-title h1 {
  color: #006ebd;
  text-shadow: 2px 2px black;
  font-size: 48px;
  font-weight: 700;
}/*# sourceMappingURL=style.css.map */