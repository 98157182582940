.articles {
  margin: 60px 0px 60px 0px;
}
.articles h1 {
  font-weight: 700;
  margin-bottom: 50px;
}

.partners-list-container p {
  color: rgb(153, 153, 153);
  font-size: 16px;
  text-align: start;
}
.partners-list-container .partners-list-p {
  color: black !important;
  font-size: 16px;
}
.partners-list-container h2 {
  color: rgb(0, 110, 189);
}

.partners-list {
  width: 500px;
}
@media (max-width: 600px) {
  .partners-list {
    width: 300px;
  }
}
.partners-list .partner-list-header p {
  background-color: #343a40;
  color: white;
  margin: 0;
  padding: 20px;
  font-size: 20px;
}
.partners-list .list-group-item {
  font-size: 20px;
}
.partners-list .students-list-header {
  margin: 80px 0px 80px 0px;
}

.students-list-p {
  margin: 50px 0px -60px 0px;
}/*# sourceMappingURL=style.css.map */