.articles {
	margin: 60px 0px 60px 0px;

	h1 {
		font-weight: 700;
		margin-bottom: 50px;
	}
}

.partners-list-container {
	p {
		color: rgb(153, 153, 153);
		font-size: 16px;
		text-align: start;
	}
	.partners-list-p {
		color: black !important;
		font-size: 16px;
	}
	h2 {
		color: rgb(0, 110, 189);
	}
}
.partners-list {
	width: 500px;
	@media (max-width: 600px) {
		& {
			width: 300px;
		}
	}

	.partner-list-header p {
		background-color: #343a40;
		color: white;
		margin: 0;
		padding: 20px;
		font-size: 20px;
	}
	.list-group-item {
		font-size: 20px;
	}
	.students-list-header {
		margin: 80px 0px 80px 0px;
	}
}

.students-list-p {
	margin: 50px 0px -60px 0px;
}
