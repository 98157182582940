.company-container {
	margin-bottom: 100px;
	.background-image {
		position: relative;
		.company-content {
			position: absolute;
			top: 40%;
			h2 {
				color: white;
				text-align: center;
				font-size: 60px;
			}
		}
		
	}
	.company-contact {
		margin: 90px !important;
		img {
			margin: 0 40px 10px 0;
		}
		h3 {
			color: #006ebd;
		}
		p {
			width: 180px;
		}
	}
	.company-description {
		font-size: 20px;
	}
	.register-container {
		background: linear-gradient(
				rgba(0, 110, 189, 0.9),
				rgba(0, 110, 189, 0.9)
			),
			url('../../../../assets/images/Dashboard/bg/bg_register.png');
		height: 100%;
		color: White;
		height: 250px;
		.register-btn {
			margin-left: 30px;
		}
	}
}
