.internship-id-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../../assets/images/Dashboard/DashboardImages/internshipId.jpg") no-repeat center center;
  flex-direction: column;
}
.internship-id-container .internship-id-content {
  background-color: white;
  padding: 60px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #006ebd;
}
.internship-id-container .internship-id-content p {
  color: #006ebd;
  font-weight: 800;
}
.internship-id-container .internship-id-content .edit-internship-id {
  position: relative;
}
.internship-id-container .internship-id-content .edit-internship-id .btn-close {
  position: absolute;
  left: 300px;
  top: -60px;
}
.internship-id-container .internship-id-content .internship-info1 {
  font-weight: bold;
  font-size: 17px;
  color: grey;
}
.internship-id-container .internship-id-content .internship-info2 {
  font-weight: bold;
  font-size: 16px;
  color: green;
}
.internship-id-container .internship-id-content .internship-info3 {
  font-weight: bold;
  font-size: 16px;
  color: #e0a800;
}
.internship-id-container .internship-id-content img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.internship-id-container .internship-id-details {
  display: flex;
  margin: 20px 0 20px 0;
}
.internship-id-container .internship-id-details h6 {
  color: #006ebd;
  font-weight: 800;
  padding: 5px;
}
.internship-id-container .internship-id-details img {
  width: 30px;
  height: 30px;
}
.internship-id-container .dropzone {
  border: 3px dotted grey;
  text-align: center;
  margin-bottom: 10px;
}
.internship-id-container .dropzone:hover {
  cursor: pointer;
  border-color: #006ebd;
}
.internship-id-container .processing-application {
  color: #e0a800 !important;
  text-align: center;
  border: 3px dotted;
}
.internship-id-container .success-application {
  color: white !important;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  background-color: green;
}/*# sourceMappingURL=style.css.map */