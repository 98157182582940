@keyframes TopBottom {
	0% {
		bottom: 300px;
	}
	100% {
		bottom: 136px;
	}
}

.homepage-container {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	&-background {
		width: 100%;
		height: 520px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(to top, #136df594, #3db2f5cc),
			url('../../../../assets/images/home/home-background.jpg') no-repeat
				no-repeat center -100px;
		background-size: cover;
	}
	&-header {
		color: white;
		h1 {
			font-size: 4rem;
			font-weight: 600;
		}
	}
	&-content {
		display: flex;
		flex: 1 1 0px;
		color: White;
		padding: 30px;
		background: linear-gradient(to top, #699deb94, #e0fd5ccc);
		.student {
			display: flex;
			flex-direction: column;
			width: 100%;
			border-radius: 20px;
			background: linear-gradient(to top, #136df594, #3df5becc),
				url('../../../../assets/images/home/student.jpg') no-repeat no-repeat
					center;
			background-size: cover;
			padding: 20px;
			margin-right: 20px;
			h3 {
				margin-bottom: 20px;
			}
			.student-info {
				max-width: 600px;
			}
		}
		.company {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			border-radius: 20px;
			text-align: end;
			background: linear-gradient(to top, #136df594, #f8466dcc),
				url('../../../../assets/images/home/ceo.jpg') no-repeat no-repeat center;
			background-size: cover;
			padding: 20px;
		}
		.click-btn {
			width: 100px;
			text-align: center;
			border-radius: 10px;
		}
	}
	.no-internships-available {
		width: 100%;
		height: 300px;
		display: none;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 136px;
		animation: TopBottom 0.5s linear;
		&-content {
			height: 200px;
			width: 500px;
			display: flex;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			padding: 37px;
			border-radius: 20px;
			background-color: White;
			color: rgba(0, 110, 189, 0.8980392157);
			font-weight: 700;
		}
	}
}
