.admins-container {
	padding: 45px;
	.post-new-admin {
		.btn-close {
			position: absolute;
			right: 23px;
			top: 13px;
		}
	}

	.change-pass {
		position: relative;
	}

	.show-hide-pass {
		position: absolute;
		top: 294px;
		right: 12px;
	}
}
