.footer-container {
  font-weight: 500;
  font-size: 18px;
  padding: 0.5rem 4rem;
}
.footer-container .footer-links a {
  text-decoration: none;
  color: black;
  line-height: 1.5;
}
.footer-container .footer-images img,
.footer-container .footer-images-2 img {
  height: 80px;
  margin-bottom: 16px;
}
.footer-container .footer-images img {
  margin-right: 6px;
}
.footer-container .footer-images-2 img {
  margin-right: 10px;
}
.footer-container .footer-links .nav-link {
  padding: 0;
}/*# sourceMappingURL=style.css.map */