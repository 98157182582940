.internship-content {
	.internships-description {
		max-width: 350px;
	}
	.delete-internship {
		height: 60px;
	}
	.confirmation-popup {
		position: fixed;
		z-index: 100;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #abcbf0a8;
		right: 0;
		.confirmation-container {
			h2 {
				color: black;
				font-weight: bold;
				font-size: 22px;
				margin-bottom: 25px;
			}
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: #ffffffc7;

			border-radius: 20px;
			border: 4px solid black;
			padding: 20px;

			.confirm-buttons button {
				margin-right: 20px;
				width: 150px;
			}
		}
	}
	.internship-header {
		height: 480px;
		position: absolute;
		z-index: 1;
		display: flex;
		align-items: center;
		h1 {
			color: White;
			font-weight: bold;
			font-size: 50px;
			line-height: 63px;
			margin-left: 73px;
		}
	}
	img {
		width: 100%;
		height: 480px;
		position: relative;
		z-index: 0;
	}

	.internship-list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 100px;
		.post-internship-container {
			background-color: #006ebd;
			border-radius: 20px;
			color: white;
			padding: 40px;
			margin-bottom: 20px;
			position: relative;
			.close-add-internship {
				position: absolute;
				right: 24px;
				top: 16px;
			}
		}
		a {
			text-decoration: none;
			color: none;
		}
		.add-internship {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			margin-bottom: 20px;
		}
		.list-group {
			width: 800px;
		}
	}
}
.internship-status {
	.internship-info1 {
		font-weight: bold;
		font-size: 16px;
		color: grey;
	}
	.internship-info2 {
		font-weight: bold;
		font-size: 16px;
		color: green;
	}
	.internship-info3 {
		font-weight: bold;
		font-size: 16px;
		color: #e0a800;
	}
	img {
		width: 30px;
		height: 30px;
		margin-bottom: 5px;
	}
}
