.dashboard-companies .confirmation-popup {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(171, 203, 240, 0.6588235294);
  right: 0;
}
.dashboard-companies .confirmation-popup .confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7803921569);
  border-radius: 20px;
  border: 4px solid black;
  padding: 20px;
}
.dashboard-companies .confirmation-popup .confirmation-container h2 {
  color: black;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
}
.dashboard-companies .confirmation-popup .confirmation-container .confirm-buttons button {
  margin-right: 20px;
  width: 150px;
}
.dashboard-companies-header {
  height: 480px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.dashboard-companies-header h1 {
  color: White;
  font-weight: bold;
  font-size: 50px;
  line-height: 63px;
  margin-left: 73px;
}
.dashboard-companies .post-new-company {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0 50px 0;
}
.dashboard-companies .post-new-company h3 {
  color: #006ebd;
}
.dashboard-companies .post-new-company .post-new-company-content {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #006ebd;
  padding: 70px;
  position: relative;
}
.dashboard-companies .post-new-company .post-new-company-content .close-post-company {
  position: absolute;
  top: 17px;
  right: 32px;
}
.dashboard-companies .post-new-company .form-control {
  width: 247px;
}
.dashboard-companies .post-new-company .PhoneInputInput {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dashboard-companies #dashboard-company-img {
  width: 100%;
  height: 480px;
  position: relative;
  z-index: 0;
}
.dashboard-companies .add-company {
  display: flex;
  margin: 50px 0px 0px 30px;
  padding: 20px;
  width: 318px;
}
.dashboard-companies .add-company h6 {
  margin: 5px 0 0 0;
  font-family: noto-sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #006ebd;
}
.dashboard-companies .add-company h6:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.dashboard-companies .card-deck {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0 100px 0px;
}
.dashboard-companies .card-deck .card {
  position: relative;
  width: 18rem;
  margin: 10px;
}
.dashboard-companies .card-deck .card-body {
  margin-bottom: 20px;
}
.dashboard-companies .card-deck .card a {
  color: "#006EBD";
}
.dashboard-companies .card-deck .card-title {
  font-weight: 700;
}
.dashboard-companies .card-deck .card-details {
  font-size: 15px;
  color: black;
}
.dashboard-companies .card-deck .card .delete-company {
  position: absolute;
  bottom: 0;
  width: 100%;
}/*# sourceMappingURL=style.css.map */