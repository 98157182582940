.generic-page-container {
  min-height: 80vh;
  overflow: hidden;
}
.generic-page-container .generic-page-content h1 {
  font-size: 5rem;
}
.generic-page-container .generic-page-content p {
  font-weight: 500;
  font-size: 18px;
}/*# sourceMappingURL=style.css.map */