.dashboard-navbar {
  width: 280px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 2px 4px 12px rgba(111, 159, 215, 0.25);
  z-index: 1;
  position: fixed;
  height: 100%;
  top: 196px;
  transition: all 0.7s ease-in-out;
}
.dashboard-navbar.dashboard-resize {
  top: 144px;
  transition: all 0.7s ease-in-out;
}
@media (max-height: 800px) {
  .dashboard-navbar {
    overflow-y: scroll;
  }
}
.dashboard-navbar img {
  width: 108px;
  height: 64px;
  margin-top: 91px;
  margin-bottom: 34px;
}
.dashboard-navbar .logout-btn {
  margin-top: 15px;
  border: 3px solid #006ebd;
  font-weight: bold;
}
.dashboard-navbar hr.solid {
  border-top: 2px solid #bbb;
  margin-top: 20px;
}
.dashboard-navbar .dashboard-user-info-container {
  margin-top: 20px;
}
.dashboard-navbar .dashboard-user-info-container .dashboard-user-info h6 {
  font-family: noto-sans;
  color: #242f3c;
  margin-top: 14px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
.dashboard-navbar .dashboard-user-info-container .dashboard-user-info p {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: -5px;
}
.dashboard-navbar .dashboard-user-info-container .dashboard-avatar svg {
  width: 78px;
  height: 78px;
  border-radius: 40px;
  box-shadow: 2px 4px 12px rgba(111, 159, 215, 0.25);
}
.dashboard-navbar .dashboard-utilities {
  margin-top: 10px;
  height: 100%;
}
.dashboard-navbar .dashboard-utilities a {
  text-decoration: none;
}
.dashboard-navbar .dashboard-utilities .dashboard-utilities-style {
  display: flex;
  width: 100%;
  height: 25px;
  align-items: center;
  margin-bottom: 15px;
  height: 35px;
}
.dashboard-navbar .dashboard-utilities .dashboard-utilities-style:hover {
  background-color: #dff1ff;
  border-radius: 6px;
  padding: 2px;
}
.dashboard-navbar .dashboard-utilities .dashboard-utilities-style h6 {
  margin-left: 21px;
  margin-bottom: 0px;
  font-family: noto-sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #006ebd;
}
.dashboard-navbar .dashboard-utilities .dashboard-utilities-style img {
  width: 30px;
  height: 30px;
  margin: 0 -1px 0 18px;
}/*# sourceMappingURL=style.css.map */