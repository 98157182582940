.company-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to top, rgba(19, 109, 245, 0.5803921569), rgba(248, 70, 109, 0.8)), url("../../../../assets/images/home/ceo.jpg") no-repeat no-repeat center;
  background-size: cover;
}
.company-form-content {
  width: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
}
.company-form-content-inputs {
  position: relative;
  -webkit-animation: fromTop 0.3s linear;
          animation: fromTop 0.3s linear;
  width: 500px;
  background: white;
  padding: 40px;
  border-radius: 20px;
}
.company-form-content-inputs .lets-start {
  color: rgba(0, 110, 189, 0.8980392157);
  text-align: center;
  font-weight: 600;
}
.company-form-content-inputs .form-label {
  color: black;
}
.company-form-content-inputs .btn-primary {
  background-color: rgba(0, 110, 189, 0.8980392157);
  width: 100%;
}/*# sourceMappingURL=style.css.map */