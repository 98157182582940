.internship-content .internships-description {
  max-width: 350px;
}
.internship-content .delete-internship {
  height: 60px;
}
.internship-content .confirmation-popup {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(171, 203, 240, 0.6588235294);
  right: 0;
}
.internship-content .confirmation-popup .confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7803921569);
  border-radius: 20px;
  border: 4px solid black;
  padding: 20px;
}
.internship-content .confirmation-popup .confirmation-container h2 {
  color: black;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
}
.internship-content .confirmation-popup .confirmation-container .confirm-buttons button {
  margin-right: 20px;
  width: 150px;
}
.internship-content .internship-header {
  height: 480px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.internship-content .internship-header h1 {
  color: White;
  font-weight: bold;
  font-size: 50px;
  line-height: 63px;
  margin-left: 73px;
}
.internship-content img {
  width: 100%;
  height: 480px;
  position: relative;
  z-index: 0;
}
.internship-content .internship-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}
.internship-content .internship-list .post-internship-container {
  background-color: #006ebd;
  border-radius: 20px;
  color: white;
  padding: 40px;
  margin-bottom: 20px;
  position: relative;
}
.internship-content .internship-list .post-internship-container .close-add-internship {
  position: absolute;
  right: 24px;
  top: 16px;
}
.internship-content .internship-list a {
  text-decoration: none;
  color: none;
}
.internship-content .internship-list .add-internship {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.internship-content .internship-list .list-group {
  width: 800px;
}

.internship-status .internship-info1 {
  font-weight: bold;
  font-size: 16px;
  color: grey;
}
.internship-status .internship-info2 {
  font-weight: bold;
  font-size: 16px;
  color: green;
}
.internship-status .internship-info3 {
  font-weight: bold;
  font-size: 16px;
  color: #e0a800;
}
.internship-status img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}/*# sourceMappingURL=style.css.map */