.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  margin-top: -100px;
}
.login-page .login-input-container {
  margin-bottom: 100px;
}
.login-page .img-container {
  width: 100%;
}
.login-page #cmmi-logo {
  margin-bottom: 30px;
}
.login-page .input-container input {
  width: 350px;
  margin-top: 20px;
  height: 40px;
  background: #dff1ff;
  border-radius: 5px;
  border: none;
  border-color: transparent;
  font-family: montserrat-regular;
  font-weight: bold;
  padding-left: 20px;
}
.login-page .input-container input::-moz-placeholder {
  color: #6f9fd7;
  font-family: montserrat-regular;
  font-weight: bold;
}
.login-page .input-container input:-ms-input-placeholder {
  color: #6f9fd7;
  font-family: montserrat-regular;
  font-weight: bold;
}
.login-page .input-container input::placeholder {
  color: #6f9fd7;
  font-family: montserrat-regular;
  font-weight: bold;
}
.login-page .input-container input:focus::-moz-placeholder {
  color: transparent;
}
.login-page .input-container input:focus:-ms-input-placeholder {
  color: transparent;
}
.login-page .input-container input:focus::placeholder {
  color: transparent;
}
.login-page .input-container .eye {
  color: #6f9fd7;
}
.login-page .input-container .eye.eye-open {
  position: relative;
  right: 30px;
  width: 20px;
}
.login-page .input-container .eye.eye-closed {
  position: relative;
  right: 30px;
}
.login-page .input-container .eye:hover {
  cursor: pointer;
}
.login-page .remember-pc {
  width: 320px;
}
.login-page .login-btn {
  font-weight: bold;
  font-size: 18px;
  border: 3px solid #006ebd;
}
.login-page .tuiasi-logo {
  position: absolute;
  bottom: 0;
}/*# sourceMappingURL=style.css.map */