.d-session-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.d-session-container .d-session-background-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(3px);
  background: url("../../../../assets//images/Dashboard/bg/stagii.jpg") no-repeat center center;
  background-size: cover;
}
.d-session-container #edit-intern-session-container {
  margin-top: 0;
}/*# sourceMappingURL=style.css.map */