.generic-page-header h2.useful-header {
  color: #006ebd;
  text-shadow: 3px 3px white;
}

.useful-documents-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.useful-documents-container .useful-documents-content {
  width: 500px;
  text-align: center;
}/*# sourceMappingURL=style.css.map */