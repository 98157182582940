.press-container {
	.press-header-container {
		display: flex;
		justify-content: center;
		align-items: center;
		.press-header-bg {
			position: relative;
			background: url('../../../../assets/images/press/bg-imgs/press-release-bg.jpg')
				center center fixed;
			background-size: cover;
			height: 400px;
			width: 100%;
			filter: blur(2px);
		}
		.press-header-title {
			position: absolute;
			color: white;
			text-align: center;
			width: 100%;
			h1 {
				line-height: 1.3;
				font-weight: bold;
				text-shadow: 3px 3px 4px #000000;
			}
		}
	}
	.press-document {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 100px;
		padding: 50px;
		.react-transform-wrapper {
			border: 2px solid black;
			background-color: grey;
		}
	}

	.zoom-in {
		margin-right: 10px;
	}
}
