.confirmation-popup {
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #abcbf0a8;
	right: 0;
	.confirmation-container {
		h2 {
			color: black;
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 25px;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffffffc7;

		border-radius: 20px;
		border: 4px solid black;
		padding: 20px;

		.confirm-buttons button {
			margin-right: 20px;
			width: 150px;
		}
	}
}
.add-new-admin {
	position: absolute;
	z-index: 2;
	display: flex;
	margin-left: 40px;
	top: 155px;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		text-decoration-color: #006ebd;
		span {
			background-color: #006ebd;
			border-color: 2px solid white;
		}
	}
	span {
		border-radius: 20px;
		padding: 5px;
		border: 2px solid #006ebd;
		font-size: 20px;
		margin-right: 8px;
	}
	p {
		font-size: 1.3rem;
		color: #006ebd;
		text-shadow: grey 1px 0 10px;
	}
}
.admins-container {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	a {
		text-decoration: none;
		color: black;
	}
	.admins-img-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url('../../../../assets/images/Dashboard/bg/admins.jpg')
			no-repeat center center;
		background-size: cover;
		z-index: 0;
		filter: blur(3px);
	}
	.admins-content-container {
		position: relative;
		.admins-logo {
			display: flex;
			justify-content: center;
			img {
				background-color: white;
				position: relative;
				top: 60px;
				border-radius: 60px;
			}
		}
		.delete-admin {
			position: absolute;
			right: 40px;
			top: 130px;
		}
		.admins-content {
			background: white;
			padding: 60px;
			border-radius: 20px;
			margin: 15px;
			min-width: 330px;
		}
	}
}

.post-new-admin {
	position: relative;
	padding: 40px;
	background-color: white;
	border-radius: 20px;
	border: 2px solid #006ebd;
	.close-post-admin {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.add-admin-password {
		position: relative;
		span {
			position: absolute;
			top: 40px;
			right: 10px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
