.confirmation-popup {
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #abcbf0a8;
	right: 0;
	.confirmation-container {
		h2 {
			color: black;
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 25px;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffffffc7;

		border-radius: 20px;
		border: 4px solid black;
		padding: 20px;

		.confirm-buttons button {
			margin-right: 20px;
			width: 150px;
		}
	}
}
.intern-session-id-container {
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #dff1ff;
	flex-direction: column;

	.intern-session-background-img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		filter: blur(3px);
		background: url('../../../../assets/images/Dashboard/bg/stagii.jpg')
			no-repeat center center;
		background-size: cover;
	}
	.add-new-session {
		font-weight: 800;
		font-size: 18px;
		color: black;
		margin-bottom: 20px;
		padding: 10px;
		border-radius: 20px;
		text-shadow: 2px 2px white;
		filter: blur(0);
		&:hover {
			cursor: pointer;
			text-decoration: underline;
			text-decoration-thickness: 2px;
			color: #006ebd;
		}
	}

	#intern-session-id-content {
		width: 500px;
		padding: 50px;
		margin-bottom: 20px;
		position: relative;
		border: 1px solid rgb(146, 143, 143);
		border-radius: 20px;
		box-shadow: 4px 3px 3px #006ebd;
		background: white;
		.intern-session-id-details {
			display: flex;
			margin: 20px 0 20px 0;
			h6 {
				color: #006ebd !important;
				font-weight: 800;
				padding: 5px;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.delete-session {
			position: absolute;
			right: 50px;
			top: 20px;
			&:hover {
				cursor: pointer;
			}
			&:hover .delete-session-message {
				cursor: pointer;
				visibility: visible;
			}

			.delete-session-message {
				visibility: hidden;
				width: 120px;
				background-color: black;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 10px 3px;
				position: absolute;
				z-index: 1;
			}
		}
		h1 {
			font-size: 1.5rem !important;
			color: #006ebd;
		}
	}
}

.add-new-intern-session-container {
	margin-top: -20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	h2 {
		margin-bottom: 20px;
	}

	.add-new-intern-session {
		width: 500px;
		padding: 20px;
		background-color: white;
		padding: 100px;
		border-radius: 20px;
		box-shadow: 2px 2px 2px #006ebd;
		position: relative;
		.btn-close {
			position: absolute;
			top: 20px;
			right: 40px;
		}
	}
}
