.admins-container {
  padding: 45px;
}
.admins-container .post-new-admin .btn-close {
  position: absolute;
  right: 23px;
  top: 13px;
}
.admins-container .change-pass {
  position: relative;
}
.admins-container .show-hide-pass {
  position: absolute;
  top: 294px;
  right: 12px;
}/*# sourceMappingURL=style.css.map */