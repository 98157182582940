.page-loader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 9;
	background: #abcbf0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.7;
	.loader {
		width: 50px;
		height: 50px;
		border: 4px solid white;
		margin-right: 280px;
		animation: loader 2s infinite ease;
	}

	.loader-inner {
		vertical-align: top;
		display: inline-block;
		width: 100%;
		background-color: #006ebd;
		animation: loader-inner 2s infinite ease-in;
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}

		25% {
			transform: rotate(180deg);
		}

		50% {
			transform: rotate(180deg);
		}

		75% {
			transform: rotate(360deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes loader-inner {
		0% {
			height: 0%;
		}

		25% {
			height: 0%;
		}

		50% {
			height: 100%;
		}

		75% {
			height: 100%;
		}

		100% {
			height: 0%;
		}
	}
}
