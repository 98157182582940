.dashboard-company-container {
	.company-background-image {
		position: relative;
		img {
			width: 100%;
			height: 362px;
		}
		.dashboard-company-name {
			position: absolute;
			z-index: 1;
			display: flex;
			align-items: center;
			top: 170px;
			margin-left: 73px;
			h2 {
				color: White;
				font-weight: bold;
				font-size: 50px;
				line-height: 63px;
			}
		}
	}
	.dashboard-company-content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
		margin-top: 100px;
		.dashboard-company-content {
			width: 500px;
			text-align: center;
			h3 {
				font-weight: 800;
				color: #006ebd;
			}
			p {
				font-weight: 700;
			}
		}
	}
	.edit-company-container {
		display: flex;
		justify-content: center;
		// .PhoneInputCountrySelect {
		// 	display: none;
		// }
		// .PhoneInputCountrySelectArrow {
		// 	display: none;
		// }

		.PhoneInputInput {
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #212529;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			-webkit-appearance: none;
			appearance: none;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
		}
		.edit-company {
			position: relative;
			width: 500px;
			border: 2px solid #006ebd;
			border-radius: 20px;
			padding: 60px;
			margin: 50px 0 40px 0;
			.close-edit-company {
				position: absolute;
				right: 30px;
				top: 20px;
			}
		}
	}
}
