.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  background: #abcbf0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}
.page-loader .loader {
  width: 50px;
  height: 50px;
  border: 4px solid white;
  margin-right: 280px;
  -webkit-animation: loader 2s infinite ease;
          animation: loader 2s infinite ease;
}
.page-loader .loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #006ebd;
  -webkit-animation: loader-inner 2s infinite ease-in;
          animation: loader-inner 2s infinite ease-in;
}
@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}/*# sourceMappingURL=style.css.map */