.public-internships-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
}
.public-internships-container .public-internships-content {
  width: 850px;
}
.public-internships-container .public-internships-content table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
  font-weight: bold;
}
.public-internships-container .public-internships-content .table-bordered th {
  border: none !important;
}
.public-internships-container .public-internships-content thead tr th:first-child {
  border-left: 5px solid #006ebd !important;
}
.public-internships-container .public-internships-content th {
  height: 60px;
  vertical-align: middle;
  text-align: center;
  background-color: #006ebd;
  color: white;
  border-top: 5px solid #006ebd;
  border-right: 5px solid #006ebd;
}
.public-internships-container .public-internships-content td {
  text-align: center;
  vertical-align: middle;
}
.public-internships-container .public-internships-content table tr td {
  border-right: 5px solid #cde5f8;
  border-bottom: 5px solid #cde5f8;
  padding: 5px;
}
.public-internships-container .public-internships-content table tr th:first-child,
.public-internships-container .public-internships-content table tr td:first-child {
  border-left: 5px solid #cde5f8;
}
.public-internships-container .public-internships-content table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
.public-internships-container .public-internships-content table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
.public-internships-container .public-internships-content table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
.public-internships-container .public-internships-content table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.public-internships-container .public-internships-content .open-internships-container .open-internship-info {
  font-weight: bold;
  font-size: 16px;
  color: green;
}
.public-internships-container .public-internships-content .closed-internship-container .closed-internship-info {
  font-weight: bold;
  font-size: 16px;
  color: rgb(168, 166, 166);
}/*# sourceMappingURL=style.css.map */