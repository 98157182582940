.icontent-student {
	position: relative;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-height: 892px) {
		height: 100%;
	}
	.iheader-student {
		color: white;
	}
	.internship-status {
		.internship-info2 {
			font-weight: bold;
			font-size: 16px;
			color: green;
		}
		.internship-info3 {
			font-weight: bold;
			font-size: 16px;
			color: grey;
		}
		img {
			width: 30px;
			height: 30px;
			margin-bottom: 5px;
		}
	}
	.internship-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url('../../../../assets/images/Dashboard/bg/student-internships.jpg')
			no-repeat center center fixed;
		background-size: cover;
		filter: blur(3px);
	}
	.ilist-student {
		margin-top: 0;
		position: relative;
		.icontainer-student {
			margin-top: 40px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex-wrap: wrap;
			padding: 20px;
			background-color: #0000008f;
			border-radius: 20px;
			.internship-content {
				background: white;
				margin-bottom: 20px;
				padding: 20px;
				border-radius: 20px;
				.internship-description {
					display: flex;
					padding: 20px;
					font-size: 18px;
					.company-name {
						color: #006ebd;
						font-weight: bold;
						font-size: 17px;
					}
					img {
						width: 30px;
						height: 30px;
						position: relative;
						z-index: 0;
					}
					a {
						margin-left: 20px;
						height: 40px;
					}
				}
			}
		}
	}
}

.internship-fallback {
	text-align: center;
	font-weight: bold;
	font-size: 17px;
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		max-width: 252px;
	}
}
