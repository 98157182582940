.page-not-found-container {
	min-height: 50vh;
	.page-not-found-content {
		h1 {
			font-size: 5rem;
		}
		p {
			font-weight: 500;
			font-size: 18px;
		}
		.home-button {
			padding: 10px 20px;
			font-weight: 500;
			font-size: 20px;
			border-radius: 20px;
		}
	}
}
