.press-container .press-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.press-container .press-header-container .press-header-bg {
  position: relative;
  background: url("../../../../assets/images/press/bg-imgs/press-release-bg.jpg") center center fixed;
  background-size: cover;
  height: 400px;
  width: 100%;
  filter: blur(2px);
}
.press-container .press-header-container .press-header-title {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
}
.press-container .press-header-container .press-header-title h1 {
  line-height: 1.3;
  font-weight: bold;
  text-shadow: 3px 3px 4px #000000;
}
.press-container .press-document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  padding: 50px;
}
.press-container .press-document .react-transform-wrapper {
  border: 2px solid black;
  background-color: grey;
}
.press-container .zoom-in {
  margin-right: 10px;
}/*# sourceMappingURL=style.css.map */