.generic-page-container {
	min-height: 50vh;
	overflow: hidden;
	.generic-page-content {
		h1 {
			font-size: 5rem;
		}
		p {
			font-weight: 500;
			font-size: 18px;
		}
	}
}
