.companies-container {
  margin-bottom: 120px;
}
.companies-container a {
  text-decoration: none;
}
.companies-container h1 {
  font-size: 54px;
}
.companies-container .card-deck {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 100px;
}
.companies-container .card-deck .card {
  width: 18rem;
  margin: 10px;
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  top: 0;
  transition: top ease-in 0.3s;
  background-color: #dff1ff;
}
.companies-container .card-deck .card:hover {
  top: -30px;
}
.companies-container .card-deck .card a {
  color: "#006EBD";
}
.companies-container .card-deck .card-title {
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.companies-container .card-deck .card-details {
  font-size: 16px;
  font-weight: 600;
  color: black;
}/*# sourceMappingURL=style.css.map */