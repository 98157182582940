.menu-container {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 196px;
}
.menu-container .scroll-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: all 0.4s ease-in-out;
}
.menu-container .scroll-header-container .images-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-container .scroll-header-container .images-container img {
  height: 90px;
  transition: all 0.7s ease-in-out;
}
.menu-container .scroll-header-container .images-container-shrink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-container .scroll-header-container .images-container-shrink img {
  height: 40px;
  transition: all 0.7s ease-in-out;
}
.menu-container .scroll-header-container .text-container span {
  color: rgba(128, 128, 128, 0.7803921569);
}
.menu-container .navbar-container {
  width: 100%;
  font-family: noto-sans-bold;
  font-weight: 500;
  padding: 0.5rem 4rem;
}
.menu-container .navbar-nav a {
  font-size: 17px;
}
.menu-container .navbar-brand img {
  height: 80px;
}
.menu-container .dropdown-menu {
  top: 40px;
}
.menu-container .multi-level-dropdown .nav-link {
  color: black !important;
}/*# sourceMappingURL=style.css.map */