.generic-page-header h2.useful-header {
	color: #006ebd;
	text-shadow: 3px 3px white;
}
.selection-documents-container {
	display: flex;
	justify-content: center;
	align-items: center;
	.selection-documents-context {
		width: 800px;
		text-align: center;
	}
}
