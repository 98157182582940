.login-page {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	position: relative;
	margin-top: -100px;
	.login-input-container {
		margin-bottom: 100px;
	}
	.img-container {
		width: 100%;
	}

	#cmmi-logo {
		margin-bottom: 30px;
	}

	.input-container {
		input {
			width: 350px;
			margin-top: 20px;
			height: 40px;
			background: #dff1ff;
			border-radius: 5px;
			border: none;
			border-color: transparent;
			font-family: montserrat-regular;
			font-weight: bold;
			padding-left: 20px;
			&::placeholder {
				color: #6f9fd7;
				font-family: montserrat-regular;
				font-weight: bold;
			}
			&:focus::placeholder {
				color: transparent;
			}
		}
		.eye {
			color: #6f9fd7;
			&.eye-open {
				position: relative;
				right: 30px;
				width: 20px;
			}
			&.eye-closed {
				position: relative;
				right: 30px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	.remember-pc {
		width: 320px;
	}
	.login-btn {
		font-weight: bold;
		font-size: 18px;
		border: 3px solid #006ebd;
	}
	.tuiasi-logo {
		position: absolute;
		bottom: 0;
	}
}
