.dashboard-company-container .company-background-image {
  position: relative;
}
.dashboard-company-container .company-background-image img {
  width: 100%;
  height: 362px;
}
.dashboard-company-container .company-background-image .dashboard-company-name {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  top: 170px;
  margin-left: 73px;
}
.dashboard-company-container .company-background-image .dashboard-company-name h2 {
  color: White;
  font-weight: bold;
  font-size: 50px;
  line-height: 63px;
}
.dashboard-company-container .dashboard-company-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
}
.dashboard-company-container .dashboard-company-content-container .dashboard-company-content {
  width: 500px;
  text-align: center;
}
.dashboard-company-container .dashboard-company-content-container .dashboard-company-content h3 {
  font-weight: 800;
  color: #006ebd;
}
.dashboard-company-container .dashboard-company-content-container .dashboard-company-content p {
  font-weight: 700;
}
.dashboard-company-container .edit-company-container {
  display: flex;
  justify-content: center;
}
.dashboard-company-container .edit-company-container .PhoneInputInput {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dashboard-company-container .edit-company-container .edit-company {
  position: relative;
  width: 500px;
  border: 2px solid #006ebd;
  border-radius: 20px;
  padding: 60px;
  margin: 50px 0 40px 0;
}
.dashboard-company-container .edit-company-container .edit-company .close-edit-company {
  position: absolute;
  right: 30px;
  top: 20px;
}/*# sourceMappingURL=style.css.map */