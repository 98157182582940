.confirmation-popup {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(171, 203, 240, 0.6588235294);
  right: 0;
}
.confirmation-popup .confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7803921569);
  border-radius: 20px;
  border: 4px solid black;
  padding: 20px;
}
.confirmation-popup .confirmation-container h2 {
  color: black;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
}
.confirmation-popup .confirmation-container .confirm-buttons button {
  margin-right: 20px;
  width: 150px;
}

.intern-session-id-container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dff1ff;
  flex-direction: column;
}
.intern-session-id-container .intern-session-background-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  filter: blur(3px);
  background: url("../../../../assets/images/Dashboard/bg/stagii.jpg") no-repeat center center;
  background-size: cover;
}
.intern-session-id-container .add-new-session {
  font-weight: 800;
  font-size: 18px;
  color: black;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  text-shadow: 2px 2px white;
  filter: blur(0);
}
.intern-session-id-container .add-new-session:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  color: #006ebd;
}
.intern-session-id-container #intern-session-id-content {
  width: 500px;
  padding: 50px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid rgb(146, 143, 143);
  border-radius: 20px;
  box-shadow: 4px 3px 3px #006ebd;
  background: white;
}
.intern-session-id-container #intern-session-id-content .intern-session-id-details {
  display: flex;
  margin: 20px 0 20px 0;
}
.intern-session-id-container #intern-session-id-content .intern-session-id-details h6 {
  color: #006ebd !important;
  font-weight: 800;
  padding: 5px;
}
.intern-session-id-container #intern-session-id-content .intern-session-id-details img {
  width: 30px;
  height: 30px;
}
.intern-session-id-container #intern-session-id-content .delete-session {
  position: absolute;
  right: 50px;
  top: 20px;
}
.intern-session-id-container #intern-session-id-content .delete-session:hover {
  cursor: pointer;
}
.intern-session-id-container #intern-session-id-content .delete-session:hover .delete-session-message {
  cursor: pointer;
  visibility: visible;
}
.intern-session-id-container #intern-session-id-content .delete-session .delete-session-message {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 3px;
  position: absolute;
  z-index: 1;
}
.intern-session-id-container #intern-session-id-content h1 {
  font-size: 1.5rem !important;
  color: #006ebd;
}

.add-new-intern-session-container {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.add-new-intern-session-container h2 {
  margin-bottom: 20px;
}
.add-new-intern-session-container .add-new-intern-session {
  width: 500px;
  padding: 20px;
  background-color: white;
  padding: 100px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #006ebd;
  position: relative;
}
.add-new-intern-session-container .add-new-intern-session .btn-close {
  position: absolute;
  top: 20px;
  right: 40px;
}/*# sourceMappingURL=style.css.map */