.page-not-found-container {
  min-height: 50vh;
}
.page-not-found-container .page-not-found-content h1 {
  font-size: 5rem;
}
.page-not-found-container .page-not-found-content p {
  font-weight: 500;
  font-size: 18px;
}
.page-not-found-container .page-not-found-content .home-button {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 20px;
}/*# sourceMappingURL=style.css.map */