.generic-page-container {
  overflow: hidden;
  font-size: 19px;
}
.generic-page-container .generic-page-background-image {
  position: relative;
  height: 428px;
}
.generic-page-container .generic-page-background-image img {
  height: 100%;
  background-size: cover;
  -o-object-fit: cover;
     object-fit: cover;
}
.generic-page-container .generic-page-header {
  position: absolute;
  top: 40%;
}
.generic-page-container .generic-page-header h2 {
  color: white;
  text-align: center;
  font-size: 60px;
  text-shadow: 3px 3px #006ebd;
}
.generic-page-container .generic-page-context-container {
  background-color: #f5f5f5;
  padding: 100px;
}
.generic-page-container .generic-page-context-container .generic-page-context-content {
  border: 2px solid white;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 5px 10px 10px 10px #bcd6eb;
}/*# sourceMappingURL=style.css.map */